import { useI18n } from 'vue-i18n'
import { useToast } from 'primevue/usetoast'
import { TOAST_LIFETIME } from '@/const/config'

export default (): Record<string, any> => {
  const
    { t } = useI18n(),
    toast = useToast(),

    tWarn = (summary, detail, params = {}) => {
      toast.add({ severity: 'success', summary: t(summary, params), detail: t(detail, params), life: TOAST_LIFETIME })
    },

    tSuccess = (summary, detail, params = {}) => {
      toast.add({ severity: 'success', summary: t(summary, params), detail: t(detail, params), life: TOAST_LIFETIME })
    },

    tInfo = (summary, detail, params = {}) => {
      toast.add({ severity: 'info', summary: t(summary, params), detail: t(detail, params), life: TOAST_LIFETIME })
    },

    tError = (summary, detail, params = {}) => {
      toast.add({ severity: 'error', summary: t(summary, params), detail: t(detail, params), life: TOAST_LIFETIME })
    }

  return {
    tWarn,
    tSuccess,
    tInfo,
    tError
  }
}
