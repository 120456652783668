import { defineStore } from 'pinia'
import api from '@/config/axios-instance'
import { ref } from 'vue'
import { loadStripe } from '@stripe/stripe-js'

export const usePaymentStore = defineStore('payment', () => {

  const payStripe = async (payload): Promise<any> => {
    const response = await api.post('stripe', payload)
    return response
  }

  const
    stripe = ref(),
    elements = ref(),

    initStripe = async (): Promise<any> => {
      stripe.value = await loadStripe('pk_test_51MCMz3LetksXZCAzZD8rqJWGJ0Nqv7mlHkhB4trC37CKZlyZ7Vr9feCKULpfyghsqz1s7vQwvTWGDEyvdKJd83pS00inQzFD8n')
      elements.value = stripe.value.elements()
    },

    getFondyCheckoutLink = async (data): Promise<any> => {
      const resp = await api.post('payments/fondy', data)
      console.log('resp.data -> ', resp)
      return resp.data
    },

    completePayment = async (data): Promise<any> => {
      const resp = await api.post('payments', data)
      console.log('resp.data -> ', resp)
      return resp
    }

  return { stripe, elements, payStripe, initStripe, getFondyCheckoutLink, completePayment }
})
