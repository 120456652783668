<template>
  <div class="banner-element-one my-paroller" data-paroller-direction="horizontal" data-paroller-factor="0.1" data-paroller-type="foreground">
    <img alt="element" src="/assets/images/banner/ship1.png">
  </div>
  <!--
  <div class="banner-element-two my-paroller" data-paroller-direction="horizontal" data-paroller-factor="0.05" data-paroller-type="foreground">
    <img alt="element" src="/assets/images/banner/man1.png">
  </div>
  <div class="banner-element-three my-paroller" data-paroller-direction="horizontal" data-paroller-factor="0.07" data-paroller-type="foreground">
    <img alt="element" src="/assets/images/banner/man3.png">
  </div>
  <div class="banner-element-four my-paroller" data-paroller-direction="horizontal" data-paroller-factor="0.07" data-paroller-type="foreground">
    <img alt="element" src="/assets/images/banner/man2.png">
  </div>
  <div class="banner-element-five my-paroller" data-paroller-direction="horizontal" data-paroller-factor="0.07" data-paroller-type="foreground">
    <img alt="element" src="/assets/images/banner/ship2.png">
  </div>
  -->
  <div class="banner-element-six my-paroller" data-paroller-direction="horizontal" data-paroller-factor="0.07" data-paroller-type="foreground">
    <img alt="element" src="/assets/images/banner/Ixi.png">
  </div>
  <div class="banner-element-seven my-paroller" data-paroller-direction="horizontal" data-paroller-factor="0.07" data-paroller-type="foreground">
    <img alt="element" src="/assets/images/banner/ship3.png">
  </div>
  <div class="banner-element-eight">
    <img alt="element" src="/assets/images/banner/line3.png">
  </div>
  <div class="banner-element-nine">
    <img alt="element" src="/assets/images/banner/line4.png">
  </div>
  <div class="banner-element-ten">
    <img alt="element" src="/assets/images/banner/circle2.png">
  </div>
  <div class="banner-element-eleven">
    <img alt="element" src="/assets/images/banner/circle1.png">
  </div>
  <div class="banner-element-twelve">
    <img alt="element" src="/assets/images/banner/line3.png">
  </div>
  <div class="banner-element-thirteen">
    <img alt="element" src="/assets/images/banner/line6.png">
  </div>
  <div class="banner-element-fourteen">
    <img alt="element" src="/assets/images/banner/line6.png">
  </div>
  <div class="banner-element-fifteen">
    <img alt="element" src="/assets/images/banner/line3.png">
  </div>
  <div class="banner-element-sixteen">
    <img alt="element" src="/assets/images/banner/line7.png">
  </div>
  <div class="banner-element-seventeen">
    <img alt="element" src="/assets/images/banner/line1.png">
  </div>
  <div class="banner-element-eightteen">
    <img alt="element" src="/assets/images/banner/manyline.png">
  </div>
  <div class="banner-element-nineteen">
    <img alt="element" src="/assets/images/banner/whitedot.png">
  </div>
</template>
<script lang="ts" setup>
</script>
