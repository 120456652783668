<template>
  <section class="client-section bg pt-120 pb-120">
    <div class="client-element-one">
      <img alt="shape" src="assets/images/circle-1.png">
    </div>
    <div class="client-element-two">
      <img alt="shape" src="assets/images/circle-2.png">
    </div>
    <div class="client-element-three">
      <img alt="shape" src="assets/images/join/icon-5.png">
    </div>
    <div class="client-element-four">
      <img alt="shape" src="assets/images/join/icon-6.png">
    </div>
    <div class="client-element-five">
      <img alt="shape" src="assets/images/join/icon-7.png">
    </div>
    <div class="client-element-six">
      <img alt="shape" src="assets/images/join/icon-5.png">
    </div>
    <div class="client-element-seven">
      <img alt="shape" src="assets/images/join/icon-6.png">
    </div>
    <div class="client-element-eight">
      <img alt="shape" src="assets/images/join/icon-7.png">
    </div>
    <div class="container">
      <div class="row justify-content-center align-items-center mb-30-none">
        <div class="col-lg-7 mb-30">
          <div class="client-item-area">
            <div class="ruddra-next">
              <img alt="next" src="assets/images/next.png">
            </div>
            <div class="ruddra-prev">
              <img alt="prev" src="assets/images/prev.png">
            </div>
            <div class="client-wrapper">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="client-item text-center">
                    <div class="client-thumb">
                      <img alt="client" src="assets/images/client.png">
                    </div>
                    <div class="client-details">
                      <div class="client-ratings">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </div>
                      <p>So easy to discover incredible new games,
                        I can start playing immediately;
                        one click and I’m in!</p>
                      <div class="client-name">
                        <span class="title">Donnie Neal </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="client-item text-center">
                    <div class="client-thumb">
                      <img alt="client" src="assets/images/client.png">
                    </div>
                    <div class="client-details">
                      <div class="client-ratings">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </div>
                      <p>So easy to discover incredible new games,
                        I can start playing immediately;
                        one click and I’m in!</p>
                      <div class="client-name">
                        <span class="title">Donnie Neal </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="client-item text-center">
                    <div class="client-thumb">
                      <img alt="client" src="assets/images/client.png">
                    </div>
                    <div class="client-details">
                      <div class="client-ratings">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </div>
                      <p>So easy to discover incredible new games,
                        I can start playing immediately;
                        one click and I’m in!</p>
                      <div class="client-name">
                        <span class="title">Donnie Neal </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="client-item text-center">
                    <div class="client-thumb">
                      <img alt="client" src="assets/images/client.png">
                    </div>
                    <div class="client-details">
                      <div class="client-ratings">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </div>
                      <p>So easy to discover incredible new games,
                        I can start playing immediately;
                        one click and I’m in!</p>
                      <div class="client-name">
                        <span class="title">Donnie Neal </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 mb-30">
          <div class="client-content">
            <h3 class="sub-title">Testimonials</h3>
            <h2 class="title">5000+ happy
              clients all around
              the world</h2>
            <div class="client-btn">
              <a class="cmn-btn" href="#0">Leave a review<i class="fas fa-long-arrow-alt-right"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
</script>
