import { defineStore } from 'pinia'
import api from '@/config/axios-instance'
import { ref } from 'vue'
import { BadgeType } from '@/interfaces/contentModels'

export const useContentStore = defineStore('content', () => {

  const
    badges = ref<BadgeType[]>([]),
    heroes = ref([])

  const getBadges = async (): Promise<any> => {
    if (badges.value.length <= 0) {
      const resp = await api.get(`content/badges`)
      badges.value = resp.data
    }
  }

  const getHeroes = async (): Promise<any> => {
    if (heroes.value.length <= 0) {
      const resp = await api.get(`content/heroes`)
      heroes.value = resp.data
    }
  }

  return { badges, getBadges, heroes, getHeroes }
})
