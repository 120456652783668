export const config = {
  cookiesAgreeDialog: false
}

export const optionsCurrency = ['USD', 'EUR', 'UAH', 'PLN']

export const getIcon = {
  USD: 'dollar',
  EUR: 'euro',
  UAH: 'hryvnia'
}

export const optionsGender = (t) => [
  { code: 'm', name: t('dash.profile.genderOpt.male') },
  { code: 'f', name: t('dash.profile.genderOpt.female') },
  { code: 'o', name: t('dash.profile.genderOpt.other') }
]
export const TOAST_LIFETIME = 5000
