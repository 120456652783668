import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './routes'
import { createPinia } from 'pinia'
import i18n from '@/config/i18n'
import applyPrimeView from './plugins/primevue'
import applyFontAwesome from './plugins/fontawesome'
import applyDirectives from '@/plugins/directives'
import applyTippyTooltip from '@/plugins/tippy'

import 'primevue/resources/themes/lara-dark-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeflex/primeflex.css'

import '@/assets/styles.scss'
import { setupAxiosInterceptors } from '@/config/axios-instance'

const app = createApp(App)

applyPrimeView(app)
applyFontAwesome(app)
applyDirectives(app)
applyTippyTooltip(app)

setupAxiosInterceptors()

app
  .use(createPinia())
  .use(i18n)
  .use(router)
  .mount('#app')
