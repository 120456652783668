import { defineStore } from 'pinia'
import api from '@/config/axios-instance'
import { ref } from 'vue'

export const useDonateStore = defineStore('donate', () => {
  const
    userHeroes = ref<Array<Record<string, any>>>([]),
    isDonateSend = ref(false),
    paymentMethods = ref<Array<Record<string, any>>>([]),
    initiatives = ref<Array<Record<any, any>>>([])

  const getUserHeroes = async (payload): Promise<void> => {
    await api.get('heroes', payload)
    isDonateSend.value = true
  }

  const doDonate = async (payload): Promise<void> => {
    await api.post('donate', payload)
    isDonateSend.value = true
  }

  const getPaymentMethods = async (): Promise<void> => {
    const resp: Array<Record<any, any>> = await api.get('donate/paymentProviders')
    paymentMethods.value = resp
  }

  const getInitiatives = async (type: string): Promise<void> => {
    const resp = await api.get(`donate/initiatives/${ type }`)
    initiatives.value = resp.data
  }

  return { isDonateSend, doDonate, userHeroes, getUserHeroes, paymentMethods, getPaymentMethods, getInitiatives, initiatives }
})
