<template>
  <AppLayout v-if="access" />
</template>
<script setup>
import AppLayout from '@/layouts/AppLayout.vue'
import { onMounted, ref } from 'vue'

const access = ref(false)
const secret = ref('0274')

onMounted(() => {
  const key = localStorage.getItem('key')
  if (key === '82356023-' + secret.value) {
    access.value = true
  }
})
</script>


