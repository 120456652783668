<template>
  <div v-if="isDataLoaded" :style="{ backgroundImage: 'url(' + require('@/assets/img/bg-donate.jpg') + ')' }" class="donatePage-cover">
    <div :style="{ backgroundColor: '#' + storeDonatePage.page.backgroundColor }" class="donatePage-component">
      <div>Режим: 1 \ 2 \ 3</div>
      <FormDonate />
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useDonatePageStore } from '@/store/donatePage'
// import { useI18n } from 'vue-i18n'
import FormDonate from '@/components/donate/FormDonate'

const
  // { t } = useI18n(),
  route = useRoute(),
  storeDonatePage = useDonatePageStore(),
  code = computed(() => route.params.code),
  isDataLoaded = computed(() => storeDonatePage.page)

onMounted(async () => {
  await storeDonatePage.get(code.value)
})
</script>
