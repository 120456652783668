import axios, { AxiosResponse } from 'axios'
import { useAuthStore } from '@/store/auth'
import { getLang } from '@/config/i18n'
import { useServiceStore } from '@/store/service'

const
  TIMEOUT = 1000000,
  SERVER_API_URL = process.env.VUE_APP_SERVER_API_URL,

  api = axios.create({
    baseURL: SERVER_API_URL
  })

// let refreshQueue = []
// let refreshInProgress = false

const
  getToken = () => localStorage.getItem('accessToken'),

  onRequestSuccess = config => {
    //
    const accessToken = getToken()
    if (accessToken) {
      if (!config.headers) config.headers = {}
      config.headers['x-access-token'] = accessToken
    }
    const store = useAuthStore()
    config.timeout = TIMEOUT
    config.url = `${ SERVER_API_URL }${ config.url }`
    config.headers.lang = getLang()
    if (store.user) {
      config.headers.userType = store.user.isStreamer ? 's' : 'd'
    }
    // console.error('! LOG onRequestSuccess: ', config)
    return config
  },

  onResponseError = async (err) => {
    // console.error('! LOG onResponseError: ', err)
    const
      status = err.status || err.response.status,
      code: number = parseInt(err.response && status),
      // originalRequest = err.config,
      token = getToken()

    console.log('status', code)
    if (code === 400 || code === 403) { // // logout case
      const store = useAuthStore()
      await store.logout()
      location.href = '/'
    }

    if (code === 520) {
      const store = useAuthStore()
      store.setError('Unknown Error')
    }
    /*
    if (!token || token === 'undefined') {
      return Promise.reject(err)
    }
    */
  },

  onResponse = (response): AxiosResponse<any> => {
    console.log('-- response --> ', response)
    if (response?.data?.accessToken) {
      const store = useAuthStore()
      store.setAccessToken(response.data.accessToken)
    }
    if (response?.data?.data?.events) {
      // console.error('!!!!!!', response.data.data.events)
      const storeService = useServiceStore()
      storeService.setEvents(response.data.data.events)
    }
    return response.data
  }

api.interceptors.request.use(onRequestSuccess)
api.interceptors.response.use(onResponse, onResponseError)

export const setupAxiosInterceptors = (): void => {
  if (axios.interceptors) {
    axios.interceptors.request.use(onRequestSuccess)
    axios.interceptors.response.use(onResponse, onResponseError)
  }
}

export default api
