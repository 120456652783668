import { createI18n } from 'vue-i18n'
import { useLocalStorage } from '@vueuse/core'

const
  user: any = useLocalStorage('user', {}, { deep: true }),
  language = useLocalStorage('lang', null)

export const optionsLang = [
  { name: 'English', code: 'en' },
  { name: 'Русский', code: 'ru' },
  { name: 'Українська', code: 'uk' }
]
export const getLang = () => user.value?.lang || language.value || navigator.language.split('-')[0] || 'en'


/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  localStorage.setItem('lang', user.value?.lang || language.value || navigator.language.split('-')[0] || 'en')
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}

export default createI18n({
  legacy: false,
  locale: getLang(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})

