<template>
  <div class="container-lootBox">
    <video id="videoOne" ref="videoOneRef">
      <source
        src="@/assets/images/play/effect.mp4"
        type="video/mp4"
      />
    </video>
    <video id="videoTwo" ref="videoTwoRef">
      <source
        src="@/assets/images/play/electric.mp4"
        type="video/mp4"
      />
    </video>
    <img :class="{ active: isHide }" alt="boxClosed" class="button-lootBox closed" src="@/assets/images/play/boxClosed.png" @click="buttonClick">
    <img :class="{ active: show }" alt="boxOpened" class="button-lootBox opened" src="@/assets/images/play/boxOpened.png">
  </div>
</template>
<script setup>
import { ref } from 'vue'

const
  videoOneRef = ref(),
  videoTwoRef = ref(),
  isHide = ref(false),
  show = ref(false),
  
  playVid = () => {
    videoTwoRef.value.play()
  },
  
  buttonClick = () => {
    playVid()
    setTimeout(() => {
      videoOneRef.value.play()
    }, 500)
    
    setTimeout(() => {
      isHide.value = true
      show.value = true
    }, 2000)
  }

</script>