<template>
  <section class="challenge-section bg">
    <div class="container">
      <div class="challenge-area">
        <div class="row justify-content-center mb-30-none">
          <div class="col-lg-4 col-md-6 col-sm-8 mb-30">
            <div class="challenge-item text-center">
              <div class="challenge-thumb">
                <img alt="challenge" src="assets/images/challenge/challenge-1.png">
              </div>
              <div class="challenge-content">
                <h3 class="title">New Tournaments Every Day</h3>
                <div class="challenge-btn">
                  <div class="about-btn">
                    <a class="custom-btn" href="#0">View Tournamnets <i class="fas fa-long-arrow-alt-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-8 mb-30">
            <div class="challenge-item text-center">
              <div class="challenge-thumb">
                <img alt="challenge" src="assets/images/challenge/challenge-2.png">
              </div>
              <div class="challenge-content">
                <h3 class="title">Challenge Your <br>
                  Friends</h3>
                <div class="challenge-btn">
                  <div class="about-btn">
                    <a class="custom-btn" href="#0">View Tournamnets <i class="fas fa-long-arrow-alt-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-8 mb-30">
            <div class="challenge-item text-center">
              <div class="challenge-thumb">
                <img alt="challenge" src="assets/images/challenge/challenge-3.png">
              </div>
              <div class="challenge-content">
                <h3 class="title">Available On <br>
                  Any Device</h3>
                <div class="challenge-btn">
                  <div class="about-btn">
                    <a class="custom-btn" href="#0">View Tournamnets <i class="fas fa-long-arrow-alt-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
</script>
