<template>
  <div class="language-select-list d-flex flex-wrap">
    <div class="locale-switcher language-select">
      <Dropdown v-model="selectedLang" :options="optionsLang" class="language-value" dropdownIcon="fa-solid fa-chevron-down" optionLabel="name" optionValue="code" @change="switchLang">
        <template #value="slotProps">
          <div class="flex align-items-center gap-2">
            <img :alt="slotProps.value" :src="'/img/flags/' + slotProps.value + '.svg'" height="22" width="22" />
            <span>{{ slotProps.value }}</span>
          </div>
        </template>
        <template #option="slotProps">
          <img :alt="slotProps.option.value" :src="'/img/flags/' + slotProps.option.code + '.svg'" class="lang-selector-option" />
          {{ slotProps.option.name }}
        </template>
      </Dropdown>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { optionsLang } from '@/config/i18n'
import { useLocalStorage } from '@vueuse/core'
import { useAuthStore } from '@/store/auth'

// import { useAuthStore } from '@/store/auth'

const
  { locale } = useI18n({}),
  store = useAuthStore(),
  language = useLocalStorage('lang', null), // ref(providerLocal || ''),
  
  selectedLang = ref(language.value || navigator.language.split('-')[0]),
  
  switchLang = () => {
    language.value = selectedLang.value
    locale.value = selectedLang.value
    store.lang = selectedLang.value
  }

</script>
<style lang="scss" scoped>
.p-dropdown {
  background: transparent;
  border: none;
  
  &:not(.p-disabled).p-focus {
    box-shadow: none;
  }
  
  &.select-bar:after {
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    content: "";
    display: block;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
    width: 7px;
    height: 7px;
  }
  
  &.select-bar.p-overlay-open:after {
    transform: rotate(-135deg);
  }
}
</style>

