export default {
  "404": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])},
    "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops..."])}
  },
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Made For True Gamers!"])},
  "menu": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
    "benefits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefits"])}
  },
  "menuProfile": {
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
    "donationsPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donations Page"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])}
  },
  "donationTooltip": {
    "charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can participate in one of our team's charitable initiatives, let's do good things together!"])},
    "cupOfCoffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can thank our team for creating quality content for you and its further development!"])}
  },
  "dash": {
    "switcher": {
      "viewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viewer"])},
      "streamer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamer"])}
    },
    "menu": {
      "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
      "myHeroes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Heroes"])},
      "myBadges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Badges"])},
      "myProfilePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Profile Page"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Settings"])},
      "donationsPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donations Page"])}
    },
    "main": {
      "titleTopMostDonated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOP 5 streamers you donate the most:"])},
      "titleTopMostValueDonation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOP 5 most valued donation you made:"])},
      "titleTableTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History of your Donation:"])},
      "emptyDonationTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You haven't donated a penny yet..."])}
    },
    "donationPage": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your viewers can send you donations using the link below."])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview Donations Page"])},
      "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Link"])},
      "generateQR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate QR code"])},
      "QRCodeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After scanning this QR code, your viewers will be redirected to your donation page."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text on the page:"])},
      "backgroundColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background color:"])},
      "backgroundImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Background image:"])},
      "donateBtnColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Button color:"])},
      "pageDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Design"])},
      "btn": {
        "saveAsImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the QR code image"])},
        "copyImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy URL of the QR code image"])}
      },
      "toast": {
        "successCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donations Page Link Copied"])},
        "successCopiedDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your viewers can send you donations using the copied link"])},
        "successCopiedQRCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place this QR code image at your stream description that your viewers can send you donations"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donations Page"])},
        "successDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Donations Page was successfully updated"])},
        "errorDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot update Donations Page"])}
      }
    },
    "badges": {
      "getAchievement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Achievement:"])},
      "toDoSo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To do so:"])},
      "specialBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["special achievement"])},
      "youGotMaxLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have reached the maximum level!"])},
      "conditionToGetWas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the condition of achievement was:"])},
      "currentLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Level:"])},
      "leftToTheNextLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left to the next level:"])}
    },
    "profile": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "socialConnections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Connections"])},
      "aboutMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Me"])},
      "aboutMeTitleWithBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill information about yourself and get a"])},
      "socialConnectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect additional social network to your account"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email:"])},
      "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Name:"])},
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language:"])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency:"])},
      "referralLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can share you referral link with streamers to get achievement and bonus chest!"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender:"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birthday:"])},
      "placeholderBirthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your birthday:"])},
      "placeholderSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select:"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country:"])},
      "favoriteStreamers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite Streamer:"])},
      "favoriteGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite Games:"])},
      "helpTooltipStreamers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can specify several favorite streamers each time you press Enter:"])},
      "helpTooltipGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can specify several favorite games each time you press Enter:"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type here:"])},
      "wishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wishes/ideas for the Donate Heroes team:"])},
      "genderOpt": {
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
      },
      "toast": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
        "detailSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updated successfully"])},
        "detailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot update profile. Try again later"])},
        "successCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Link Copied"])},
        "successCopiedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you can share you referral link with streamers to get achievement and bonus chest!"])}
      },
      "toastFile": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error upload a file"])},
        "detailWarnFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image has to be 'jpeg' or 'png' format"])},
        "detailWarnSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image has to be less than 1000Kb"])}
      },
      "toastSocialError": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Connection Error"])},
        "alreadyRegister": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cannot connect with ", _interpolate(_named("provider")), " as it was register separately"])}
      }
    }
  },
  "cookie": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We ask for your permission for a better experience."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts."])},
    "acceptText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept All Cookies"])},
    "declineText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decline All Cookies"])}
  },
  "auth": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join us / Login with:"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join us / Login"])},
    "alreadyHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You already have account"])},
    "signInVia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in via"])},
    "signInViaAnotherPlatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or sign in via another platform"])},
    "confirmText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["By authorizing, you confirm that you have read carefully and agree to our ", _interpolate(_named("terms")), " and our ", _interpolate(_named("privacy")), "."])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "tabTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have a DonateHeroes account?"])},
    "tabText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you sign in through the new platform, we recommend that you link your account in the \"Settings\" section of the dashboard. This will help you avoid creating additional DonateHeroes accounts."])}
  },
  "links": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Mission"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Team"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently Questions"])},
    "forInvestors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For investors"])},
    "cooperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooperation"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "termsOfService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "joinUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join us!"])}
  },
  "messages": {
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
    "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "subjectPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is this about?"])},
    "messagePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell your thoughts"])},
    "needAuth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization required"])},
    "sayYourName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please say your name first!"])},
    "toast": {
      "s_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message Sent"])},
      "s_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your feedback!"])},
      "e_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message wasn't sent"])},
      "e_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while sending your message!"])}
    }
  },
  "dialog": {
    "donate": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift for Donate-Heroes Team!"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support content creator by paying the transaction fee."])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate amount of"])},
      "confirmText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["I accept and agree with the ", _interpolate(_named("terms")), " and our ", _interpolate(_named("privacy")), "."])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More detail"])},
      "detailText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You voluntarily send a donation to the author using a payment service.\n\nYou agree that the informational services are provided to you in full and that you have no property claims against the «Donate Heroes» service.\n\nA donation is a voluntary contribution of funds for media content. You cannot demand a donation refund. Avoid scammers and do not send funds for any services unrelated to donations."])}
    },
    "initiative": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select one of the initiatives"])}
    }
  },
  "successPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your support!"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to dashboard"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipisicing elit."])}
  },
  "footer": {
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright"])},
    "allRightsReserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Rights Reserved By"])}
  },
  "general": {
    "toast": {
      "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request Error"])},
      "errorDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot get the correct server response, please try again later or contact our support"])}
    }
  },
  "events": {
    "congratulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulation!"])},
    "youGetBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You get Achievement:"])}
  },
  "btn": {
    "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donate"])},
    "great": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great!"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Message"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])}
  }
}