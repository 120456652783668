export default {
  "404": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что-то пошло не так"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуться на главную"])},
    "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Упс..."])}
  },
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделано для настоящих геймеров!"])},
  "menu": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О Нас"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новости"])},
    "benefits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преимущества"])}
  },
  "menuProfile": {
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["События"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кошелек"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркет"])},
    "donationsPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страница Донатов"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])}
  },
  "donationTooltip": {
    "charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете принять участие в одной из благотворительных инициатив нашей команды, давайте вместе делать добрые дела!"])},
    "cupOfCoffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете поблагодарить нашу команду за созданный для Вас качественный контент и его дальнейшее развитие!"])}
  },
  "dash": {
    "switcher": {
      "viewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зритель"])},
      "streamer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стример"])}
    },
    "menu": {
      "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основное"])},
      "myHeroes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои Герои"])},
      "myBadges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои Достижения"])},
      "myProfilePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мой Профиль"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка Аккаунта"])},
      "donationsPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страница Донатов"])}
    },
    "main": {
      "titleTopMostDonated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТОП-5 стримеров которым вы донатите:"])},
      "titleTopMostValueDonation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТОП-5 ваших самых крупных донатов:"])},
      "titleTableTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История ваших донатов:"])},
      "emptyDonationTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы еще ни копейки не пожертвовали..."])}
    },
    "donationPage": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По указанной ниже ссылке Ваши зрители могут отправить Вам донаты."])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварительный просмотр страницы донатов"])},
      "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копировать ссылку"])},
      "generateQR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерировать QR-код"])},
      "QRCodeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши зрители попадут на вашу страницу доната после сканирования этого QR-кода."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст на странице:"])},
      "backgroundColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет фона:"])},
      "backgroundImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изображение фона:"])},
      "donateBtnColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет кнопки:"])},
      "pageDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дизайн страницы"])},
      "btn": {
        "saveAsImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать PNG картинку QR-кода"])},
        "copyImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировать URL картинки QR-кода"])}
      },
      "toast": {
        "successCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на страницу донатов скопирована"])},
        "successCopiedDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши зрители могут отправлять Вам платные сообщения используя скопированную ссылку"])},
        "successCopiedQRCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поместите изображение QR-кода в описание вашего стрима, чтобы Ваши зрители могли отправлять Вам донаты"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страница Донатов"])},
        "successDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша страница донатов успешно обновлена"])},
        "errorDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно обновить страницу донатов"])}
      }
    },
    "badges": {
      "getAchievement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получи достижение:"])},
      "toDoSo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для этого:"])},
      "specialBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["особое достижение"])},
      "youGotMaxLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы достигли максимального уровня!"])},
      "conditionToGetWas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["условием достижения было:"])},
      "currentLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий уровень:"])},
      "leftToTheNextLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До следующего уровеня:"])}
    },
    "profile": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
      "socialConnections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Социальные сети"])},
      "aboutMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обо мне"])},
      "aboutMeTitleWithBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, заполните информацию о себе и получите"])},
      "socialConnectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключить дополнительную социальную сеть к вашему аккаунту"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта:"])},
      "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше Имя:"])},
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык:"])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта:"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пол:"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День рождения:"])},
      "placeholderBirthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите дату:"])},
      "placeholderSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите:"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон:"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна:"])},
      "favoriteStreamers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Любимые стримеры:"])},
      "favoriteGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Любимые игры:"])},
      "helpTooltipStreamers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете указать несколько любимых стримеров при каждом нажатии клавиши Enter"])},
      "helpTooltipGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете указать несколько любимых игр при каждом нажатии клавиши Enter"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите сюда:"])},
      "wishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожелания/идеи для команды Donate Heroes:"])},
      "genderOpt": {
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мужской"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Женский"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другой"])}
      },
      "toast": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
        "detailSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно обновлен"])},
        "detailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно обновить профиль. Повторите попытку позже"])}
      },
      "toastFile": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при загрузке файла"])},
        "detailWarnFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изображение должно быть в формате 'jpeg' или 'png'"])},
        "detailWarnSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер изображения должен быть менее 1000 Кб"])}
      },
      "toastSocialError": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка подключения к социальной сети"])},
        "alreadyRegister": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Невозможно подключиться к ", _interpolate(_named("provider")), ", так как он был зарегистрирован отдельно"])}
      }
    }
  },
  "cookie": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы просим вашего разрешения для улучшения работы."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нажимая \"Принять\", вы соглашаетесь сохранять файлы cookie на вашем устройстве для улучшения навигации по сайту, анализа использования сайта и улучшения качества наших маркетинговых активностей."])},
    "acceptText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принять"])},
    "declineText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонить"])}
  },
  "auth": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присоединяйтесь к нам / Войдите с помощью:"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выход"])},
    "alreadyHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас уже есть аккаунт"])},
    "signInVia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти через"])},
    "signInViaAnotherPlatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["или войти через другую платформу"])},
    "confirmText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Авторизуясь, Вы подтверждаете, что внимательно прочитали и согласились с нашими ", _interpolate(_named("terms")), " предоставления услуг и нашей ", _interpolate(_named("privacy")), "."])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условиями"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политикой конфиденциальности"])},
    "tabTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уже есть аккаунт в DonateHeroes?"])},
    "tabText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прежде чем авторизоваться через новую платформу, рекомендуем привязать Ваш аккаунт через раздел \"Настройки\" панели управления. Это поможет избежать создания дополнительных аккаунтов на DonateHeroes."])}
  },
  "links": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О Нас"])},
    "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша Миссия"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Команда"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часто задаваемые вопросы"])},
    "forInvestors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для инвесторов"])},
    "cooperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сотрудничество"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжитесь с нами"])},
    "termsOfService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия использования"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика конфиденциальности"])},
    "joinUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присоединяйтесь!"])}
  },
  "dialog": {
    "donate": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодарность команде Donate-Heroes!"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
      "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кому"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплатить комиссию за стримера"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма доната"])},
      "confirmText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Я принимаю и соглашаюсь с ", _interpolate(_named("terms")), " и нашей ", _interpolate(_named("privacy")), "."])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
      "detailText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы добровольно отправляете донат автору с помощью платежного сервиса.\n\nВы соглашаетесь, что информационные услуги предоставлены вам в полном объеме, что у вас нет никаких имущественных претензий к сервису «Donate Heroes».\n\nДонат — это добровольное пожертвование средств за медиа-контент. Вы не можете требовать возврат доната. Избегайте мошенников и не присылайте средства за любые услуги, не связанные с донатами."])}
    },
    "initiative": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите одну из инициатив"])}
    }
  },
  "successPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо за Вашу поддержку!"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуться к дашборду"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "general": {
    "toast": {
      "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка запроса"])},
      "errorDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось получить правильный ответ сервера, пожалуйста повторите попытку позже или свяжитесь с нашей службой поддержки"])}
    }
  },
  "events": {
    "congratulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поздравляем!"])},
    "youGetBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы получили Достижение:"])}
  },
  "btn": {
    "great": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отлично!"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр"])}
  }
}