import { defineStore } from 'pinia'
import api from '@/config/axios-instance'
import { ref } from 'vue'

export const useDonatePageStore = defineStore('donatePage', () => {

  const page = ref()

  const save = async (payload): Promise<any> => {
    const response = await api.post('streamer/page', payload)
    return response.data
  }

  const get = async (pageCode): Promise<any> => {
    const resp = await api.get(`streamer/page/${ pageCode }`)
    console.log('resp.data -> ', resp.data)
    page.value = resp.data
  }

  const generateQR = async (pageCode): Promise<any> => {
    const resp = await api.post(`streamer/generateQR/${ pageCode }`)
    console.log('resp.data -> ', resp.data)
    return resp.data
  }

  return { page, get, save, generateQR }
})
