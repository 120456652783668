import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
// import { far } from '@fortawesome/free-regular-svg-icons'
library.add(fas, fab)
dom.watch()

const applyFontAwesome = (app) => {
  app.component('fa-icon', FontAwesomeIcon)
}

export default applyFontAwesome
