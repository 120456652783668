<template>
  <div v-if="$" class="preloader">
    <div class="preloader-inner">
      <div class="preloader-icon">
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'

const { $ } = window
const
  isLoading = ref(),
  start = () => {
    isLoading.value = true
    console.log('LOG -> START')
    $('.preloader').css('display', 'block')
    $('.preloader').delay(200).animate({ 'opacity': '1' }, 200)
  },
  stop = () => {
    $('.preloader').delay(400).animate({ 'opacity': '0' }, 400, () => {
      $('.preloader').css('display', 'none')
      isLoading.value = false
    })
  }

onMounted(() => {
  if (!$) return
  $(document).ready(function () {
    // preloader
    stop()
    
    // Create Background Image
    $('.bg_img').css('background-image', function () {
      return ('url(' + $(this).data('background') + ')')
    })
  })
})

defineExpose({ start, stop, isLoading })
</script>
