import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/pages/Home.vue'
import Donate from '@/pages/payment/Donate.vue'
import NotFound from '@/pages/service/404.vue'
import NoAccess from '@/pages/service/no-access.vue'
import { useAuthStore } from '@/store/auth'
import StripeCheckout from '@/pages/payment/StripeCheckout.vue'
import Success from '@/pages/payment/successPayment.vue'
import LootBox from '@/components/common/boxes/LootBox.vue'
import TestSecret from '@/components/common/boxes/TestSecret.vue'
import Leaderboard from '@/components/common/Leaderboard.vue'
import Failure from '@/components/payments/stripe/Failure.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/pages/dashboard/index.vue'),
    meta: {
      layout: 'AppLayoutDashboard',
      auth: false
    }
  },
  {
    path: '/dashboard/settings',
    name: 'settings',
    component: () => import('@/pages/dashboard/Settings.vue'),
    meta: {
      layout: 'AppLayoutDashboard'
    }
  },
  {
    path: '/dashboard/profile-page',
    name: 'profile-page',
    component: () => import('@/pages/dashboard/_tmp_ProfilePage.vue'),
    meta: {
      layout: 'AppLayoutDashboard'
    }
  },
  {
    path: '/dashboard/heroes',
    name: 'heroes',
    component: () => import('@/pages/dashboard/Heroes.vue'),
    meta: {
      layout: 'AppLayoutDashboard'
    }
  },
  {
    path: '/dashboard/badges',
    name: 'badges',
    component: () => import('@/pages/dashboard/Badges.vue'),
    meta: {
      layout: 'AppLayoutDashboard'
    }
  },
  {
    path: '/dashboard/donations-page',
    name: 'donationsPage',
    component: () => import('@/pages/dashboard/DonationsPage.vue'),
    meta: {
      layout: 'AppLayoutDashboard'
    }
  },
  /*
  {
    path: '/dashboard/settings/social',
    name: 'settings',
    component: () => import('@/pages/dashboard/Settings.vue'),
    meta: {
      layout: 'AppLayoutDashboard'
    }
  },
  */
  {
    path: '/account/oauth',
    name: 'oauth',
    component: () => import('@/pages/service/auth.vue'),
    meta: {
      layout: 'AppLayoutEmpty',
      auth: false
    }
  },
  {
    path: '/donate/:code',
    name: 'donate',
    component: Donate,
    meta: {
      layout: 'AppLayoutEmpty',
      auth: false
    }
  },
  {
    path: '/checkout/:code',
    name: 'checkout',
    component: StripeCheckout,
    meta: {
      layout: 'AppLayoutEmpty'
    }
  },
  {
    path: '/success',
    name: 'Success',
    component: Success,
    meta: {
      layout: 'AppLayoutEmpty'
    }
  },
  {
    path: '/test',
    name: 'Test',
    component: LootBox,
    meta: {
      layout: 'AppLayoutAnimation',
      auth: false
    }
  },
  {
    path: '/secret',
    name: 'Secret',
    component: TestSecret,
    meta: {
      layout: 'AppLayoutAnimation',
      auth: false
    }
  },
  {
    path: '/failure',
    name: 'Failure',
    component: Failure,
    meta: {
      layout: 'AppLayoutEmpty'
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/pages/AboutView.vue'),
    meta: {
      auth: false
    }
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/pages/News.vue'),
    meta: {
      auth: false
    }
  },
  {
    path: '/news/:code',
    name: 'news-details',
    component: () => import('@/pages/NewsDetails.vue'),
    meta: {
      auth: false
    }
  },
  {
    path: '/mission',
    name: 'mission',
    component: () => import('@/pages/info/Mission.vue'),
    meta: {
      layout: 'AppLayoutInfoPage',
      auth: false
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/pages/info/Faq.vue'),
    meta: {
      layout: 'AppLayoutInfoPage',
      auth: false
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/pages/info/Terms.vue'),
    meta: {
      layout: 'AppLayoutInfoPage',
      auth: false
    }
  },
  {
    path: '/privacy',
    name: 'privacy-policy',
    component: () => import('@/pages/info/PrivacyPolicy.vue'),
    meta: {
      layout: 'AppLayoutInfoPage',
      auth: false
    }
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: {
      layout: 'AppLayoutDefault',
      auth: false
    }
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: Leaderboard,
    meta: {
      layout: 'AppLayoutDefault',
      auth: false
    }
  },
  {
    path: '/no-access',
    name: 'no-access',
    component: NoAccess,
    meta: {
      layout: 'AppLayoutEmpty',
      auth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  /*
  const
    key1 = localStorage.getItem('key1'),
    key2 = localStorage.getItem('key2'),
    key3 = localStorage.getItem('key3')

  if ((key1 && key2 && key3 && (Number(key3) - Number(key2) - 1) * Number(key1) * Number(key2) * Number(key3) !== 9318074327) || !key3 || !key1 || !key2) {
    next('/no-access')
    return
  }
  */

  if (to.matched.length === 0) {
    next('/404')
    return
  }

  const
    store = useAuthStore(),
    publicPages = ['/', '/donate', '/account/oauth'],
    publicPagesIncludes = publicPages.filter(s => s.includes(to.path)),
    loggedIn = store.getToken && store.user

  // console.log('LOG -> ', publicPagesIncludes.length)
  if (to.matched.some(x => x.meta.auth === false)) {
    next()
  } else if (publicPagesIncludes.length <= 0 && !loggedIn) {
    next('/')
  } else {
    next()
  }
})

export default router
