<template>
  <section id="about" class="about-section bg pt-120 pb-120">
    <div class="about-element-one">
      <img alt="icon" src="assets/images/about/icon-1.png">
    </div>
    <div class="about-element-two">
      <img alt="icon" src="assets/images/about/icon-2.png">
    </div>
    <div class="about-element-three">
      <img alt="icon" src="assets/images/about/icon-3.png">
    </div>
    <div class="about-element-four">
      <img alt="icon" src="assets/images/about/icon-4.png">
    </div>
    <div class="about-element-five">
      <img alt="icon" src="assets/images/about/icon-5.png">
    </div>
    <div class="about-element-six">
      <img alt="icon" src="assets/images/about/icon-5.png">
    </div>
    <div class="about-element-seven">
      <img alt="icon" src="assets/images/about/icon-6.png">
    </div>
    <div class="about-element-eight">
      <img alt="icon" src="assets/images/about/icon-7.png">
    </div>
    <div class="about-element-nine">
      <img alt="icon" src="assets/images/about/icon-8.png">
    </div>
    <div class="about-element-ten">
      <img alt="icon" src="assets/images/about/icon-9.png">
    </div>
    <div class="container">
      <div class="row justify-content-center align-items-center mb-30-none">
        <div class="col-lg-6 mb-30">
          <div class="about-item-area">
            <div class="row justify-content-center mb-30-none">
              <div class="col-lg-6">
                <div class="about-item text-center">
                  <div class="about-icon">
                    <img alt="about" src="assets/images/about/about-1.png">
                  </div>
                  <h3 class="title">Great PC Games</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur tur adipiscing elit. </p>
                  <div class="about-btn">
                    <a class="custom-btn" href="#0">Read More<i class="fas fa-long-arrow-alt-right"></i></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="about-item about-item--style text-center mt-30">
                  <div class="about-icon">
                    <img alt="about" src="assets/images/about/about-2.png">
                  </div>
                  <h3 class="title">Platform for gaming</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur tur adipiscing elit. </p>
                  <div class="about-btn">
                    <a class="custom-btn" href="#0">Read More<i class="fas fa-long-arrow-alt-right"></i></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-30">
                <div class="about-item text-center">
                  <div class="about-icon">
                    <img alt="about" src="assets/images/about/about-3.png">
                  </div>
                  <h3 class="title">Friends & community</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur tur adipiscing elit. </p>
                  <div class="about-btn">
                    <a class="custom-btn" href="#0">Read More<i class="fas fa-long-arrow-alt-right"></i></a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-30">
                <div class="about-item about-item--style-two text-center mt-30">
                  <div class="about-icon">
                    <img alt="about" src="assets/images/about/about-4.png">
                  </div>
                  <h3 class="title">Customer service</h3>
                  <p>Lorem ipsum dolor sit amet, consectetur tur adipiscing elit. </p>
                  <div class="about-btn">
                    <a class="custom-btn" href="#0">Read More<i class="fas fa-long-arrow-alt-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 mb-30">
          <div class="about-content">
            <h3 class="sub-title">ABOUT Donation Heroes</h3>
            <h2 class="title">Innovate donation platform with game mechanics you love.</h2>
            <p>Create your own donation Profile and be a real hero for the content creators and any other organization you like.</p>
            <p>You can be a part of our Heroes Universal</p>
            
            <p>All the games, all the time. No additional purchases or revolving monthly catalogs. Play whatever you want, whenever you want, as many times as you want.</p>
            <p>vestibulum urna id, vulputate ipsum. Integer sodales nisl eget eros lacinia, varius pulvinar justo convallis. Ut id ante non quam viverra egestas vel ac eros. Morbi ac rutrum erat.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
            <div class="about-content-btn">
              <a class="cmn-btn" href="#0">Read Our Story<i class="fas fa-long-arrow-alt-right"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
</script>
