export const setCookie = (name, value, exp) => {
  const date = new Date()
  date.setTime(date.getTime() + exp * 60 * 1000)
  const expires = 'expires=' + date.toUTCString()
  document.cookie = name + '=' + value + ';' + expires + ';path=/'
}

export const getCookie = (name) => {
  const value = '; ' + document.cookie
  const parts: any = value.split('; ' + name + '=')
  if (parts.length == 2) return parts.pop().split(';').shift()
  return ''

}
