// import jsonToFormData from 'json-form-data'

export const multiFileUploadConfig = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}

export function jsonToFormData(data) {
  const formData = new FormData()

  buildFormData(formData, data)

  return formData
}

export function buildFormData(formData, data, parentKey: string | null = null) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${ parentKey }[${ key }]` : key)
    })
  } else {
    const value = data == null ? '' : data

    formData.append(parentKey, value)
  }
}
