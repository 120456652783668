import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useServiceStore = defineStore('service', () => {

  const
    isEventShowing = ref(false),
    events = ref([])

  const
    setEvents = (payload) => {
      events.value = payload
    }

  return { isEventShowing, events, setEvents }
})
