import { v4 as uuidv4 } from 'uuid'
import qs from 'qs'
import { ref } from 'vue'
import { openSignInWindow } from '@/utils/windowPopup'

export default (): Record<string, any> => {
  const
    providers = ref([
      { name: 'twitch', color: '#772ce8' },
      { name: 'youtube', color: '#ff0000' },
      // { name: 'steam', color: '#0e1c25' },
      { name: 'discord', color: '#5968ba' }
    ]),

    provider = ref(),
    state = ref(),

    receiveFunctionBefore = ref(),

    initiatePopup = (url, callback, closeCallback) => {
      console.log('provider.value -> ', provider.value)
      const receiveFunction = async event => {
        console.log('receiveFunction.value -> ', provider.value)
        console.log('LOG -> receiveMessage', event)
        const { data } = event
        // console.log('LOG -> data = ', data, typeof data)
        if (typeof data !== 'string' || (typeof data === 'string' && data[0] !== '?' && data[0] !== '#')) {
          return
        }

        let query: any = {}

        switch (provider.value) {
          case 'discord':
          case 'twitch':
            query = qs.parse(data.replace('?', ''))
            break
          case 'youtube':
            query = qs.parse(data.replace('#', ''))
            break
        }
        console.log('callback -> ', { query, provider: provider.value })
        await callback({ query, provider: provider.value })
      }

      openSignInWindow(url, provider.value + '-dh-auth', receiveFunction, receiveFunctionBefore, closeCallback)
    },

    connectWithProvider = async (_provider, callback, closeCallback) => {
      provider.value = _provider
      state.value = uuidv4()
      console.log(`onLogin ${ _provider } -> `)
      localStorage.setItem(`${ _provider }State`, state.value)

      let query, url

      switch (_provider) {
        case 'twitch':
          query = {
            response_type: 'code',
            client_id: process.env.VUE_APP_TWITCH_CLIENT_ID,
            redirect_uri: process.env.VUE_APP_FRONTEND_URL + 'account/oauth',
            scope: 'user:read:email',
            state: state.value
          }
          url = `https://id.twitch.tv/oauth2/authorize?${ qs.stringify(query) }`
          break

        case 'youtube':
          query = {
            include_granted_scopes: true,
            response_type: 'token',
            client_id: process.env.VUE_APP_GOOGLE_CLIEND_ID,
            redirect_uri: process.env.VUE_APP_FRONTEND_URL + 'account/oauth',
            scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/youtube.readonly',
            state: state.value
          }
          url = `https://accounts.google.com/o/oauth2/v2/auth?${ qs.stringify(query) }`
          break

        case 'steam':
          query = {
            // include_granted_scopes: true,
            response_type: 'token',
            client_id: process.env.VUE_APP_STEAM_CLIEND_ID,
            // redirect_uri: process.env.VUE_APP_FRONTEND_URL + 'account/oauth',
            // scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/youtube.readonly',
            state: state.value
          }
          url = `https://steamcommunity.com/oauth/login?${ qs.stringify(query) }`
          break

        case 'discord':
          query = {
            // include_granted_scopes: true,
            response_type: 'code',
            client_id: process.env.VUE_APP_DISCORD_CLIEND_ID,
            redirect_uri: process.env.VUE_APP_FRONTEND_URL + 'account/oauth',
            scope: 'identify email'
            // state: state.value
          }
          url = `https://discord.com/api/oauth2/authorize?${ qs.stringify(query) }`
          break
      }
      console.log('LOG -> query = ', query)

      initiatePopup(url, callback, closeCallback)
    }

  return {
    providers,
    connectWithProvider
  }
}
