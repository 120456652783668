<template>
  <div v-if="isActive" class="cookie">
    <div class="cookie-body">
      <h2>{{ t('cookie.title') }}</h2>
      <p>{{ t('cookie.description') }}</p>
    </div>
    <div class="cookie-footer">
      <button class="cookie-button" @click="acceptHandler"> {{ t('cookie.acceptText') }}</button>
      <button class="cookie-button" @click="declineHandler"> {{ t('cookie.declineText') }}</button>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { getCookie, setCookie } from '@/utils/cookiesFunc'
import { useI18n } from 'vue-i18n'

const
  { t } = useI18n(),
  
  isActive = ref(getCookie('cookieShow') !== 'false'),
  
  acceptHandler = () => {
    isActive.value = false
    setCookie('cookieShow', false, 10) // 10 minute to clear cookie
  },
  
  declineHandler = () => {
    isActive.value = false
    setCookie('cookieShow', true, 10)
  }

</script>
