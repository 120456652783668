export default {
  "404": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щось пішло не так"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернутися на головну"])},
    "oops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ой..."])}
  },
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Створено для справжніх геймерів!"])},
  "menu": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Про Нас"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новини"])},
    "benefits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переваги"])}
  },
  "menuProfile": {
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Події"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гаманець"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркет"])},
    "donationsPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сторінка Донатів"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування"])}
  },
  "donationTooltip": {
    "charity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете взяти участь в одній із благодійних ініціатив нашої команди, давайте разом робити добрі справи!"])},
    "cupOfCoffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете подякувати нашій команді за створений для Вас якісний контент і його подальший розвиток!"])}
  },
  "dash": {
    "switcher": {
      "viewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Глядач"])},
      "streamer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стрімер"])}
    },
    "menu": {
      "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основне"])},
      "myHeroes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої Герої"])},
      "myBadges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мої Досягнення"])},
      "myProfilePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мій Профіль"])},
      "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налаштування Профілю"])},
      "donationsPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сторінка Донатів"])}
    },
    "main": {
      "titleTopMostDonated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТОП-5 стрімерів яким ви донатите:"])},
      "titleTopMostValueDonation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТОП-5 ваших найбільших донатів:"])},
      "titleTableTransaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Історія ваших донатів:"])},
      "emptyDonationTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви ще не пожертвували жодної копійки..."])}
    },
    "donationPage": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За наведеним нижче посиланням Ваші глядачі можуть надіслати Вам донати."])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попередній перегляд сторінки донатів"])},
      "copyLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копіювати посилання"])},
      "generateQR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Згенерувати QR-код"])},
      "QRCodeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваші глядачі потраплять на вашу сторінку надсилання донатів після сканування цього QR-коду."])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текст на сторінці:"])},
      "backgroundColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір фону:"])},
      "backgroundImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зображення фону:"])},
      "donateBtnColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колір кнопки:"])},
      "pageDesign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дизайн сторінки"])},
      "btn": {
        "saveAsImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачати PNG картинку QR-коду"])},
        "copyImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопіювати URL картинки QR-коду"])}
      },
      "toast": {
        "successCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посилання на сторінку донатів скопійовано"])},
        "successCopiedDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваші глядачі можуть надсилати Вам платні повідомлення за скопійованим посиланням"])},
        "successCopiedQRCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помістіть зображення QR-коду в опис вашого стріму, щоб Ваші глядачі могли надсилати Вам донати"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сторінка Донатів"])},
        "successDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашу сторінку донатів успішно оновлено"])},
        "errorDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неможливо оновити сторінку донатів"])}
      }
    },
    "badges": {
      "getAchievement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отримай досягнення:"])},
      "toDoSo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для цього:"])},
      "specialBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["особливе досягнення"])},
      "youGotMaxLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "conditionToGetWas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "currentLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "leftToTheNextLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "profile": {
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профіль"])},
      "socialConnections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соціальні мережі"])},
      "aboutMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Про мене"])},
      "aboutMeTitleWithBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь ласка, заповніть інформацію про себе та отримайте"])},
      "socialConnectDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підключити додаткову соціальну мережу до вашого акаунту"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронна пошта:"])},
      "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше ім'я:"])},
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мова:"])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Валюта:"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стать:"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День народження:"])},
      "placeholderBirthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оберіть дату:"])},
      "placeholderSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оберіть:"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон:"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Країна:"])},
      "favoriteStreamers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улюблені стрімери:"])},
      "favoriteGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улюблені ігри:"])},
      "helpTooltipStreamers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете вказати кілька улюблених стрімерів під час кожного натискання клавіші Enter"])},
      "helpTooltipGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви можете вказати кілька улюблених ігор під час кожного натискання клавіші Enter"])},
      "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введіть сюди:"])},
      "wishes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Побажання/ідеї для команди Donate Heroes:"])},
      "genderOpt": {
        "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чоловіча"])},
        "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жіноча"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інша"])}
      },
      "toast": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профіль"])},
        "detailSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успішно оновлено"])},
        "detailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдається оновити профіль. Спробуйте пізніше"])}
      },
      "toastFile": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка під час завантаження файлу"])},
        "detailWarnFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зображення має бути у форматі 'jpeg' або 'png'"])},
        "detailWarnSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Розмір зображення не повинен перевищувати 1000 Кб"])}
      },
      "toastSocialError": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка підключення до соціальної мережі"])},
        "alreadyRegister": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Неможливо під'єднатися до ", _interpolate(_named("provider")), ", оскільки він був зареєстрований окремо"])}
      }
    }
  },
  "cookie": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просимо Вашого дозволу для покращення роботи."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Натискаючи \"Прийняти\", ви погоджуєтеся зберігати файли cookie на вашому пристрої для покращення навігації сайтом, аналізу використання сайту та покращення якості наших маркетингових активностей."])},
    "acceptText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прийняти"])},
    "declineText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Відхилити"])}
  },
  "auth": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приєднуйтесь до нас / Увійдіть за допомогою:"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вихід"])},
    "alreadyHaveAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас вже є акаунт"])},
    "signInVia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увійти через"])},
    "signInViaAnotherPlatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["або увійти через іншу платформу"])},
    "confirmText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Авторизуючись, Ви підтверджуєте, що уважно прочитали та погодилися з нашими ", _interpolate(_named("terms")), " надання послуг та нашою ", _interpolate(_named("privacy")), "."])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Умовами"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Політикою конфіденційності"])},
    "tabTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уже є акаунт у DonateHeroes?"])},
    "tabText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перш ніж авторизуватися через нову платформу, рекомендуємо прив'язати Ваш акаунт через розділ \"Налаштування\" панелі управління. Це допоможе уникнути створення додаткових акаунтів на DonateHeroes."])}
  },
  "links": {
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Про Нас"])},
    "mission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наша Місія"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Команда"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часті запитання"])},
    "forInvestors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для інвесторів"])},
    "cooperation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Співпраця"])},
    "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зворотній зв'язок"])},
    "termsOfService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Умови використання"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Політика конфіденційності"])},
    "joinUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приєднуйтесь!"])}
  },
  "dialog": {
    "donate": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Донат"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис"])},
      "sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повідомлення"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Від"])},
      "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кому"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бути щедрим та сплатити комісію за стрімера"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума донату"])},
      "confirmText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Я приймаю і погоджуюся з ", _interpolate(_named("terms")), " та нашою ", _interpolate(_named("privacy")), "."])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детальніше"])},
      "detailText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви добровільно надсилаєте донат авторові за допомогою платіжного сервісу.\n\nВи погоджуєтеся, що інформаційні послуги надані вам у повному обсязі, що у вас немає жодних майнових претензій до сервісу «Donate Heroes».\n\nДонат — це добровільна пожертва коштів за медіа-контент. Ви не можете вимагати повернення донату. Уникайте шахраїв і не надсилайте кошти за будь-які послуги, не повязані з донатами."])}
    },
    "initiative": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оберіть одну з ініціатив"])}
    }
  },
  "successPage": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дякуємо за Вашу підтримку!"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернутися до дашборду"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "general": {
    "toast": {
      "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка запиту"])},
      "errorDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося отримати правильну відповідь сервера, будь ласка спробуйте пізніше або зверніться до нашої служби підтримки"])}
    }
  },
  "events": {
    "congratulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вітаємо!"])},
    "youGetBadge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ви отримали Досягнення:"])}
  },
  "btn": {
    "great": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чудово!"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перегляд"])}
  }
}