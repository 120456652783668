<template>
  <section id="game" class="game-section bg pt-120 pb-120">
    <div class="game-inner"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 text-center">
          <div class="section-header">
            <h3 class="sub-title">A MASSIVE GAME COLLECTION</h3>
            <h2 class="section-title">Play The Best of Medforce</h2>
            <p>Whether you want to go on an epic adventure, show your skills online or play together with family and friends</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mb-30-none">
        <div class="col-lg-4 col-md-6 col-sm-8 mb-30">
          <div class="game-item">
            <div class="game-thumb">
              <img alt="game" src="assets/images/game/game-1.png">
            </div>
            <div class="game-content">
              <div class="game-content-body">
                <h3 class="title">realistic battles war</h3>
                <div class="game-meta-post d-flex flex-wrap">
                  <div class="game-verson">
                    <span>Ver.2.1.5</span>
                  </div>
                  <div class="game-ratings">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                  </div>
                </div>
              </div>
              <div class="game-content-footer">
                <div class="game-content-footer-content d-flex flex-wrap align-items-center justify-content-between">
                  <div class="game-player">
                    <span>1356 Active Players</span>
                  </div>
                  <div class="game-footer-icon">
                    <i class="fas fa-long-arrow-alt-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-8 mb-30">
          <div class="game-item">
            <div class="game-thumb">
              <img alt="game" src="assets/images/game/game-2.png">
              <span class="thumb-details">Hot</span>
            </div>
            <div class="game-content">
              <div class="game-content-body">
                <h3 class="title">Realm Royale </h3>
                <div class="game-meta-post d-flex flex-wrap">
                  <div class="game-verson">
                    <span>Ver.2.1.5</span>
                  </div>
                  <div class="game-ratings">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                  </div>
                </div>
              </div>
              <div class="game-content-footer">
                <div class="game-content-footer-content d-flex flex-wrap justify-content-between">
                  <div class="game-player">
                    <span>1356 Active Players</span>
                  </div>
                  <div class="game-footer-icon">
                    <i class="fas fa-long-arrow-alt-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-8 mb-30">
          <div class="game-item">
            <div class="game-thumb">
              <img alt="game" src="assets/images/game/game-3.png">
            </div>
            <div class="game-content">
              <div class="game-content-body">
                <h3 class="title">Resident Evil 2 </h3>
                <div class="game-meta-post d-flex flex-wrap">
                  <div class="game-verson">
                    <span>Ver.2.1.5</span>
                  </div>
                  <div class="game-ratings">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                  </div>
                </div>
              </div>
              <div class="game-content-footer">
                <div class="game-content-footer-content d-flex flex-wrap justify-content-between">
                  <div class="game-player">
                    <span>1356 Active Players</span>
                  </div>
                  <div class="game-footer-icon">
                    <i class="fas fa-long-arrow-alt-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-8 mb-30">
          <div class="game-item">
            <div class="game-thumb">
              <img alt="game" src="assets/images/game/game-4.png">
            </div>
            <div class="game-content">
              <div class="game-content-body">
                <h3 class="title">Battlelands Royale</h3>
                <div class="game-meta-post d-flex flex-wrap">
                  <div class="game-verson">
                    <span>Ver.2.1.5</span>
                  </div>
                  <div class="game-ratings">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                  </div>
                </div>
              </div>
              <div class="game-content-footer">
                <div class="game-content-footer-content d-flex flex-wrap justify-content-between">
                  <div class="game-player">
                    <span>1356 Active Players</span>
                  </div>
                  <div class="game-footer-icon">
                    <i class="fas fa-long-arrow-alt-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-8 mb-30">
          <div class="game-item">
            <div class="game-thumb">
              <img alt="game" src="assets/images/game/game-5.png">
            </div>
            <div class="game-content">
              <div class="game-content-body">
                <h3 class="title">God of War </h3>
                <div class="game-meta-post d-flex flex-wrap">
                  <div class="game-verson">
                    <span>Ver.2.1.5</span>
                  </div>
                  <div class="game-ratings">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                  </div>
                </div>
              </div>
              <div class="game-content-footer">
                <div class="game-content-footer-content d-flex flex-wrap justify-content-between">
                  <div class="game-player">
                    <span>1356 Active Players</span>
                  </div>
                  <div class="game-footer-icon">
                    <i class="fas fa-long-arrow-alt-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-8 mb-30">
          <div class="game-item">
            <div class="game-thumb">
              <img alt="game" src="assets/images/game/game-6.png">
              <span class="thumb-details">Hot</span>
            </div>
            <div class="game-content">
              <div class="game-content-body">
                <h3 class="title">Call of Duty 4</h3>
                <div class="game-meta-post d-flex flex-wrap">
                  <div class="game-verson">
                    <span>Ver.2.1.5</span>
                  </div>
                  <div class="game-ratings">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                  </div>
                </div>
              </div>
              <div class="game-content-footer">
                <div class="game-content-footer-content d-flex flex-wrap justify-content-between">
                  <div class="game-player">
                    <span>1356 Active Players</span>
                  </div>
                  <div class="game-footer-icon">
                    <i class="fas fa-long-arrow-alt-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mt-60">
        <div class="game-footer-btn">
          <a class="cmn-btn-border" href="#0">Browse More Games </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
</script>
