import PrimeVue from 'primevue/config'
import Dropdown from 'primevue/dropdown'
// import DynamicDialog from 'primevue/dynamicdialog'
import Dialog from 'primevue/dialog'
import Tooltip from 'primevue/tooltip'
import Avatar from 'primevue/avatar'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Card from 'primevue/card'
import ColorPicker from 'primevue/colorpicker'
import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import InputText from 'primevue/inputtext'
import FileUpload from 'primevue/fileupload'
import Sidebar from 'primevue/sidebar'
import ProgressSpinner from 'primevue/progressspinner'
// import BadgeDirective from 'primevue/badgedirective'
import Button from 'primevue/button'
// import Breadcrumb from 'primevue/breadcrumb'
import Checkbox from 'primevue/checkbox'
import Calendar from 'primevue/calendar'
import Chip from 'primevue/chip'
import Chips from 'primevue/chips'
import Paginator from 'primevue/paginator';
// import ConfirmationService from 'primevue/confirmationservice'
// import ConfirmDialog from 'primevue/confirmdialog'
// import Column from 'primevue/column'
// import DataTable from 'primevue/datatable'
// import Divider from 'primevue/divider'
import InputMask from 'primevue/inputmask'
// import InlineMessage from 'primevue/inlinemessage'
import InputSwitch from 'primevue/inputswitch'
import InputNumber from 'primevue/inputnumber'
// import OverlayPanel from 'primevue/overlaypanel'
// import Fieldset from 'primevue/fieldset'
// import Password from 'primevue/password'
// import Row from 'primevue/row'
// import MultiSelect from 'primevue/multiselect'
// import Message from 'primevue/message'
// import Ripple from 'primevue/ripple'
// import RadioButton from 'primevue/radiobutton'
// import ScrollPanel from 'primevue/scrollpanel'
// import SelectButton from 'primevue/selectbutton'
// import Tag from 'primevue/tag'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import Textarea from 'primevue/textarea'

// import DataTable from 'primevue/datatable'
// import Column from 'primevue/column'
// import Row from 'primevue/row'                     //optional for row
// import TriStateCheckbox from 'primevue/tristatecheckbox'
// import ConfirmPopup from 'primevue/confirmpopup'

const applyPrimeView = (app) => {
  app.directive('tooltip', Tooltip)
  // app.directive('badge', BadgeDirective)
  // app.directive('ripple', Ripple)

  app.use(PrimeVue, { ripple: true })
  app.use(ToastService)
  // app.use(ConfirmationService)

  app.component('Dropdown', Dropdown)
  app.component('Dialog', Dialog)
  // app.component('DynamicDialog', DynamicDialog)
  app.component('Avatar', Avatar)
  app.component('Accordion', Accordion)
  app.component('AccordionTab', AccordionTab)
  app.component('Button', Button)
  app.component('Card', Card)
  app.component('ColorPicker', ColorPicker)
  app.component('Splitter', Splitter)
  app.component('SplitterPanel', SplitterPanel)
  // app.component('Avatar', Avatar)
  // app.component('Button', Button)
  // app.component('Breadcrumb', Breadcrumb)
  app.component('Chip', Chip)
  // app.component('Checkbox', Checkbox)
  // app.component('Column', Column)
  // app.component('ConfirmDialog', ConfirmDialog)
  // app.component('ConfirmPopup', ConfirmPopup)
  // app.component('Divider', Divider)
  app.component('InputNumber', InputNumber)
  app.component('InputText', InputText)
  app.component('InputMask', InputMask)
  app.component('InputSwitch', InputSwitch)
  // app.component('OverlayPanel', OverlayPanel)
  // app.component('Fieldset', Fieldset)
  app.component('FileUpload', FileUpload)
  // app.component('Password', Password)
  // app.component('MultiSelect', MultiSelect)
  app.component('Calendar', Calendar)
  app.component('Chips', Chips)
  // app.component('DataTable', DataTable)
  // app.component('Row', Row)
  // app.component('Message', Message)
  // app.component('InlineMessage', InlineMessage)
  // app.component('RadioButton', RadioButton)
  // app.component('ScrollPanel', ScrollPanel)
  // app.component('SelectButton', SelectButton)
  // app.component('TriStateCheckbox', TriStateCheckbox)
  // app.component('Tag', Tag)
  app.component('TabView', TabView)
  app.component('TabPanel', TabPanel)
  app.component('Textarea', Textarea)
  app.component('Toast', Toast)
  app.component('Checkbox', Checkbox)
  app.component('Sidebar', Sidebar)
  app.component('ProgressSpinner', ProgressSpinner)
  app.component('Paginator', Paginator)
}

export default applyPrimeView
