<template>
  <section class="join-section bg pt-120 pb-120">
    <div class="join-element-one">
      <img alt="icon" src="assets/images/join/icon-1.png">
    </div>
    <div class="join-element-two">
      <img alt="icon" src="assets/images/join/icon-2.png">
    </div>
    <div class="join-element-three">
      <img alt="icon" src="assets/images/join/icon-3.png">
    </div>
    <div class="join-element-four">
      <img alt="icon" src="assets/images/join/icon-4.png">
    </div>
    <div class="join-element-five">
      <img alt="icon" src="assets/images/join/icon-5.png">
    </div>
    <div class="join-element-six">
      <img alt="icon" src="assets/images/join/icon-6.png">
    </div>
    <div class="join-element-seven">
      <img alt="icon" src="assets/images/join/icon-7.png">
    </div>
    <div class="join-element-eight">
      <img alt="icon" src="assets/images/join/icon-5.png">
    </div>
    <div class="join-element-nine">
      <img alt="icon" src="assets/images/join/icon-6.png">
    </div>
    <div class="join-element-ten">
      <img alt="icon" src="assets/images/join/icon-7.png">
    </div>
    <div class="join-element-eleven">
      <img alt="icon" src="assets/images/join/icon-5.png">
    </div>
    <div class="join-element-twelve">
      <img alt="icon" src="assets/images/join/icon-6.png">
    </div>
    <div class="join-element-thirteen">
      <img alt="icon" src="assets/images/join/icon-7.png">
    </div>
    <div class="join-element-fourteen">
      <img alt="icon" src="assets/images/join/icon-5.png">
    </div>
    <div class="join-element-fifteen">
      <img alt="icon" src="assets/images/join/icon-6.png">
    </div>
    <div class="join-element-sixteen">
      <img alt="icon" src="assets/images/join/icon-7.png">
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 text-center">
          <div class="section-header">
            <h3 class="sub-title">Join players </h3>
            <h2 class="section-title">Around The World</h2>
            <p>Whether you want to go on an epic adventure, show your skills online or play together with family and friends</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4">
          <div class="counter-item mb-30">
            <div class="counter-content">
              <div class="odo-area">
                <h3
                  class="odo-title odometer"
                  data-odometer-final="50125198"
                >0</h3>
              </div>
            </div>
            <p>Page views per month</p>
          </div>
          <div class="counter-item mb-30">
            <div class="counter-content">
              <div class="odo-area">
                <h3
                  class="odo-title odometer"
                  data-odometer-final="15034087"
                >0</h3>
              </div>
            </div>
            <p>Users</p>
          </div>
          <div class="counter-item mb-30">
            <div class="counter-content">
              <div class="odo-area">
                <h3
                  class="odo-title odometer"
                  data-odometer-final="33"
                >0</h3>
              </div>
            </div>
            <p>Countries</p>
          </div>
        </div>
        <div class="col-lg-8">
          <div id="vmap">
            <div class="position-1">
              <span class="dot"></span>
              <div class="details">
                <h6 class="name">Gerd Muller</h6>
                <p class="area">From: Argentina</p>
              </div>
            </div>
            <div class="position-2">
              <span class="dot"></span>
              <div class="details">
                <h6 class="name">P Pique</h6>
                <p class="area">From: Netherland</p>
              </div>
            </div>
            <div class="position-3">
              <span class="dot"></span>
              <div class="details">
                <h6 class="name">Marcelo Viera</h6>
                <p class="area">From: Spain</p>
              </div>
            </div>
            <div class="position-4">
              <span class="dot"></span>
              <div class="details">
                <h6 class="name">Zabaleta</h6>
                <p class="area">From: Brazil</p>
              </div>
            </div>
            <div class="position-5">
              <span class="dot"></span>
              <div class="details">
                <h6 class="name">Cukuese</h6>
                <p class="area">From: Africa</p>
              </div>
            </div>
            <div class="position-6">
              <span class="dot"></span>
              <div class="details">
                <h6 class="name">Mark Haland</h6>
                <p class="area">From: Germany</p>
              </div>
            </div>
            <div class="position-7">
              <span class="dot"></span>
              <div class="details">
                <h6 class="name">Diego Costa</h6>
                <p class="area">From: Italy</p>
              </div>
            </div>
            <div class="position-8">
              <span class="dot"></span>
              <div class="details">
                <h6 class="name">Rosario</h6>
                <p class="area">From: Argentina</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
</script>
