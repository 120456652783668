export const openSignInWindow = (url, name, receiveMessage, receiveFunctionBefore, closeCallback) => {
  let windowObjectReference: Window | any = null
  let previousUrl = null
  // remove any existing event listeners
  if (receiveFunctionBefore.value) {
    window.removeEventListener('message', receiveFunctionBefore.value)
  }
  receiveFunctionBefore.value = receiveMessage

  // window features
  const popupOptions: any = {
    // scrollbars: 'no',
    // location: 'no',
    // directories: 'no'
    toolbar: 'no',
    menubar: 'no',
    width: 480,
    height: 600,
    resizable: 'yes',
    status: 'yes',
    left: (window.screen.width - 480) / 2,
    top: (window.screen.height - 560 - 100) / 2
  }

  const popupFeatureParts: Array<string> = []
  for (const [key, value] of Object.entries(popupOptions)) {
    popupFeatureParts.push(key + '=' + value)
  }

  const strWindowFeatures = popupFeatureParts.join(',')

  if (windowObjectReference === null || windowObjectReference.closed) {
    /* if the pointer to the window object in memory does not exist
     or if such pointer exists but the window was closed */
    windowObjectReference = window.open(url, name, strWindowFeatures)
  } else if (previousUrl !== url) {
    /* if the resource to load is different,
     then we load it in the already opened secondary window, and then
     we bring such window back on top/in front of its parent window. */
    windowObjectReference = window.open(url, name, strWindowFeatures)
    windowObjectReference?.focus()
  } else {
    /* else the window reference must exist and the window
     is not closed; therefore, we can bring it back on top of any other
     window with the focus() method. There would be no need to re-create
     the window or to reload the referenced resource. */
    windowObjectReference.focus()
  }

  // add the listener for receiving a message from the popup
  window.addEventListener('message', receiveMessage)
  // assign the previous URL
  previousUrl = url

  console.log('windowObjectReference -> ', windowObjectReference)

  const winClosed = setInterval(function () {
    if (windowObjectReference?.closed) {
      clearInterval(winClosed)
      // console.log(' -> On Close Window')
      window.removeEventListener('message', receiveMessage)
      closeCallback()
    }
  }, 250)
}
