<template>
  <section class="faq-section bg pt-120 pb-120">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8 text-center">
          <div class="section-header">
            <h3 class="sub-title">HOW CAN WE HELP?</h3>
            <h2 class="section-title">Frequently Asked Questions</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mb-30-none">
        <div class="col-lg-8 mb-30">
          <div class="faq-wrapper">
            <div class="faq-item">
              <h3 class="faq-title"><span class="title">Does Utomik work on mobile,Mac and Windows?</span><span class="right-icon"></span></h3>
              <div class="faq-content">
                <p>The base requirement is to simply have an internet connection in order to run gamio. Our downloading system will decide how much of the game to download before starting a game based
                  upon your connection.</p>
                <p>This means that if you have a fast connection, the games will start up faster due to downloading less. If you have a slow connection, the games will start up slower due to
                  downloading more, but this is to ensure that you have no issues while you play no matter what connection speed you have. </p>
                <p>If you would like further information about how this system works, please send an email to <a href="#0">support@medforce.com.</a></p>
              </div>
            </div>
            <div class="faq-item">
              <h3 class="faq-title"><span class="title">What kind of games are on Gamio?</span><span class="right-icon"></span></h3>
              <div class="faq-content">
                <p>The base requirement is to simply have an internet connection in order to run gamio. Our downloading system will decide how much of the game to download before starting a game based
                  upon your connection.</p>
                <p>This means that if you have a fast connection, the games will start up faster due to downloading less. If you have a slow connection, the games will start up slower due to
                  downloading more, but this is to ensure that you have no issues while you play no matter what connection speed you have. </p>
                <p>If you would like further information about how this system works, please send an email to <a href="#0">support@medforce.com.</a></p>
              </div>
            </div>
            <div class="faq-item active open">
              <h3 class="faq-title"><span class="title">What Internet speed do I need for Gamio?</span><span class="right-icon"></span></h3>
              <div class="faq-content">
                <p>The base requirement is to simply have an internet connection in order to run gamio. Our downloading system will decide how much of the game to download before starting a game based
                  upon your connection.</p>
                <p>This means that if you have a fast connection, the games will start up faster due to downloading less. If you have a slow connection, the games will start up slower due to
                  downloading more, but this is to ensure that you have no issues while you play no matter what connection speed you have. </p>
                <p>If you would like further information about how this system works, please send an email to <a href="#0">support@medforce.com.</a></p>
              </div>
            </div>
            <div class="faq-item">
              <h3 class="faq-title"><span class="title">Can I play as much as I want?</span><span class="right-icon"></span></h3>
              <div class="faq-content">
                <p>The base requirement is to simply have an internet connection in order to run gamio. Our downloading system will decide how much of the game to download before starting a game based
                  upon your connection.</p>
                <p>This means that if you have a fast connection, the games will start up faster due to downloading less. If you have a slow connection, the games will start up slower due to
                  downloading more, but this is to ensure that you have no issues while you play no matter what connection speed you have. </p>
                <p>If you would like further information about how this system works, please send an email to <a href="#0">support@medforce.com.</a></p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mb-30">
          <div class="faq-thumb">
            <img alt="faq" src="assets/images/faq/faq.png">
          </div>
        </div>
      </div>
      <div class="faq-bottom-area">
        <div class="row justify-content-center">
          <p>Do not hesitate to <span>contact us</span> if you have any questions about our pricing plans. We're here to help and don’t forget to check the <span>FAQ page.</span></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
</script>
