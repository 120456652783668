const applyDirectives = (app) => {
  app.directive('img', (url) => {
    const img: HTMLImageElement = new Image()
    img.src = url.src
    // img.setAttribute('style', 'opacity: 0')
    // img.onload = function (e: any) {
    // console.log('LOG -> ', e.path[0].src)
    // e.path[0].src = url.src
    // this.el.src = url
    // $(this.el).css('opacity', 0).animate({ opacity: 1 }, 1000)
    // }.bind(this)
  })
}


export default applyDirectives
