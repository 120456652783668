<template>
  <section class="play-section bg pt-120 pb-120">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6 text-center">
          <div class="section-header">
            <h3 class="sub-title">Amazing new games</h3>
            <h2 class="section-title">Play Every Month</h2>
            <p>Whether you want to go on an epic adventure, show your skills online or play together with family and friends</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid p-xl-0">
      <div class="row m-0">
        <div class="col-xl-6 pl-0">
          <div class="w-100 h-100 bg_img" data-background="assets/images/play/play.png"></div>
        </div>
        <div class="col-xl-6 pr-0">
          <div class="play-item-area pt-120 pb-120">
            <div class="bal-thumb bg_img d-xl-none" data-background="./assets/css/img/circle.png">
            </div>
            <div class="play-wrapper">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="play-post-thumb">
                    <img alt="play" src="assets/images/play/play-1.jpg">
                    <div class="play-thumb-overlay">
                      <div class="play-overlay-content">
                        <h3 class="title">Moto Racing 3d</h3>
                        <p>Run 20,000 meters</p>
                      </div>
                      <div class="play-overlay-footer">
                        <span class="sub-title">Player Signed</span>
                        <div class="play-sign-area d-flex flex-wrap align-items-center justify-content-between">
                          <div class="sign-thumb">
                            <a href="#0"><img alt="sign" src="assets/images/play/sign.png"></a>
                          </div>
                          <div class="sign-btn">
                            <a href="#0"><i class="fas fa-long-arrow-alt-right"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="play-post-thumb">
                    <img alt="play" src="assets/images/play/play-2.jpg">
                    <div class="play-thumb-overlay">
                      <div class="play-overlay-content">
                        <h3 class="title">Battlelands Royale</h3>
                        <p>Eliminate 10 opponents</p>
                      </div>
                      <div class="play-overlay-footer">
                        <span class="sub-title">Player Signed</span>
                        <div class="play-sign-area d-flex flex-wrap align-items-center justify-content-between">
                          <div class="sign-thumb">
                            <a href="#0"><img alt="sign" src="assets/images/play/sign.png"></a>
                          </div>
                          <div class="sign-btn">
                            <a href="#0"><i class="fas fa-long-arrow-alt-right"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="play-post-thumb">
                    <img alt="play" src="assets/images/play/play-3.jpg">
                    <div class="play-thumb-overlay">
                      <div class="play-overlay-content">
                        <h3 class="title">Extreme Battles</h3>
                        <p>Eliminate 10 opponents</p>
                      </div>
                      <div class="play-overlay-footer">
                        <span class="sub-title">Player Signed</span>
                        <div class="play-sign-area d-flex flex-wrap align-items-center justify-content-between">
                          <div class="sign-thumb">
                            <a href="#0"><img alt="sign" src="assets/images/play/sign.png"></a>
                          </div>
                          <div class="sign-btn">
                            <a href="#0"><i class="fas fa-long-arrow-alt-right"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
</script>
