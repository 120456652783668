import { defineStore } from 'pinia'
import api from '@/config/axios-instance'
import { ref } from 'vue'
import { Badge } from '@/interfaces/contentModels'

export const useUsersStore = defineStore('users', () => {

  const
    transactions = ref([]),
    pagination = ref({}),
    topDonations = ref([]),
    topDonationsByPurpose = ref([]),
    badges = ref<Badge[]>([]),
    heroes = ref([])

  const getTransactions = async (params): Promise<any> => {
    const resp = await api.post(`users/transactions`, params)
    if (resp.data) {
      const { docs, totalDocs, totalPages, page } = resp.data
      pagination.value = { totalDocs, totalPages, page }
      transactions.value = docs
    }
  }

  const getTopDonations = async (currencyCode): Promise<any> => {
    const resp = await api.get(`users/topDonations?currency=${ currencyCode }`)
    if (resp.data) {
      console.log('resp.data -> ', resp.data)
      topDonations.value = resp.data
    }
  }

  const getTopDonationsByPurpose = async (currencyCode): Promise<any> => {
    const resp = await api.get(`users/topDonationsByPurpose?currency=${ currencyCode }`)
    if (resp.data) {
      console.log('resp.data -> ', resp.data)
      topDonationsByPurpose.value = resp.data
    }
  }

  const getBadges = async (): Promise<any> => {
    const resp = await api.get(`users/badges`)
    badges.value = resp.data
  }

  const getHeroes = async (): Promise<any> => {
    const resp = await api.get(`users/heroes`)
    heroes.value = resp.data
  }

  const sendMessage = async (data: Record<string, any>): Promise<any> => {
    const resp = await api.post(`users/message`, data)
    return resp
  }

  return {
    pagination, transactions, getTransactions,
    topDonations, getTopDonations, topDonationsByPurpose, getTopDonationsByPurpose,
    badges, getBadges,
    heroes, getHeroes,
    sendMessage
  }
})
