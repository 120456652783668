import { defineStore } from 'pinia'
import api from '@/config/axios-instance'
import { computed, ref } from 'vue'
// import qs from 'qs'
import { useLocalStorage } from '@vueuse/core'
import { jsonToFormData, multiFileUploadConfig } from '@/utils/files'

export const useAuthStore = defineStore('auth', () => {

  const
    user: any = useLocalStorage('user', {}, { deep: true }), // ref(userLocal ? JSON.parse(userLocal) : null),
    accessToken = useLocalStorage('accessToken', null), // ref(accessTokenLocal || ''),
    provider = useLocalStorage('provider', null), // ref(providerLocal || ''),
    getToken = computed(() => accessToken.value),
    isAuth = computed(() => user.value && accessToken.value),
    lang = useLocalStorage('lang', null),
    userLastAuth: any = useLocalStorage('lastAuth', {}),
    requestError = ref(null)

  const
    setUser = (_user, doConnection = false) => {
      if (_user) {
        console.log('LOG -> _user', _user)
        user.value = _user
        if (!doConnection) {
          userLastAuth.value.avatar = _user.avatar
          userLastAuth.value.displayName = _user.displayName
        }
      }
    },
    setAccessToken = (token) => {
      accessToken.value = token
    },
    setProvider = (_provider) => {
      provider.value = _provider
      userLastAuth.value.provider = _provider
    },
    setError = (_error) => {
      requestError.value = _error
    },

    /*
    getUser = async (payload): Promise<any> => {
      await api.get('heroes', payload)
    },
    */

    getProfile = async (): Promise<any> => {
      const response = await api.get('users/profile')
      if (response?.data) {
        setUser(response.data)
      }
    },

    updateProfile = async (payload): Promise<any> => {
      const
        data = { ...payload },
        formData = jsonToFormData(data)
      // console.log('LOG -> data = ', data)
      const response = await api.patch('users/profile', formData, multiFileUploadConfig)
      return response?.data || response
    },

    authProvider = async (payload, provider: string, doConnection = false): Promise<any> => {
      console.log(`LOG -> ${ provider }`, payload)
      switch (provider) {
        case 'youtube':
        case 'twitch': {
          // check state
          const localState = localStorage.getItem(`${ provider }State`)
          console.log('LOG -> localState', localState, payload.state)
          if (!localState || payload.state !== localState) {
            return location.href = '/404'
          }
          break
        }
      }

      localStorage.removeItem(`${ provider }State`)
      payload.lang = lang.value

      const response = doConnection
        ? await api.patch(`users/auth/${ provider }`, payload)
        : await api.post(`users/auth/${ provider }`, payload)

      if (response?.data?.accessToken) {
        setAccessToken(response.data.accessToken)
      }
      if (response?.data?.provider && !doConnection) {
        setProvider(response.data.provider)
      }
      if (response?.data?.user) {
        setUser(response.data.user, doConnection)
        lang.value = response.data.user.lang
      }

      return response?.data
    },

    logout = async () => {
      await api.post('users/logout')
      user.value = {}
      accessToken.value = null
      provider.value = null
    }

  return {
    logout, lang, user, userLastAuth, getToken, isAuth, provider, updateProfile, setAccessToken, getProfile, authProvider,
    requestError, setError
  }
})
