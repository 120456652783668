<template>
  <div class="container-lootBox">
    <video id="videoOne" ref="videoOneRef">
      <source
        src="@/assets/images/play/effect.mp4"
        type="video/mp4"
      />
    </video>
    <video id="videoTwo" ref="videoTwoRef">
      <source
        src="@/assets/images/play/electric.mp4"
        type="video/mp4"
      />
    </video>
    <audio ref="audioRef" class="d-none" controls preload="auto">
      <source src="@/assets/images/play/voice.mp3" type="audio/mpeg">
    </audio>
    <img :class="{ active: isHide }" alt="boxClosed" class="button-lootBox closed" src="@/assets/images/play/boxClosed.png" @click="buttonClick">
    <img :class="{ active: show }" alt="boxOpened" class="button-lootBox opened" src="@/assets/images/play/imageSecret.png">
    
    <div id="ui">
      <div v-for="(item, i) in [...Array(26).keys()]" :key="i" class="text">
        {{ 'Поздравления Ани от парней Donate Heroes' }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'

const
  videoOneRef = ref(),
  videoTwoRef = ref(),
  audioRef = ref(),
  isHide = ref(false),
  show = ref(false),
  
  playVid = () => {
    videoTwoRef.value.play()
  },
  
  buttonClick = () => {
    playVid()
    setTimeout(() => {
      videoOneRef.value.play()
    }, 500)
    
    setTimeout(() => {
      audioRef.value.play()
      isHide.value = true
      show.value = true
      // audioRef.value.play();
    }, 2000)
  }

</script>
<style lang="scss" scoped>
$fontSize: 4rem;
div {
  // will-change: transform;
}

#ui {
  margin-top: 150px;
  transform-style: preserve-3d;
  text-align: center;
  .text {
    position: absolute;
    font-size: $fontSize;
    color: #fff;
    line-height: $fontSize;
    font-family: 'Anton', sans-serif;
    padding: 20px 0;
    mix-blend-mode: screen;
    transform-style: preserve-3d;
    width: 500px;
    @for $i from 1 through 26 {
      $key: $i - 1;
      $param: 5;
      &:nth-child(#{$i}) {
        clip-path: polygon(
            -30% + ($key * $param) 0,
            -20% + ($key * $param) 0,
            20% + ($key * $param) 100%,
            0% + ($key * $param) 100%
        );
        animation: wave 1600ms $key * 200 - 10000ms ease-in-out infinite alternate;
      }
    }
  }
}

@keyframes wave {
  0% {
    transform: translate(-50%, -50%) scale(0.9) rotateX(20deg) rotateY(20deg) rotateZ(0deg);
    color: rgb(68, 0, 100);
  }
  100% {
    transform: translate(-50%, -50%) scale(1.1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    color: rgb(255, 50, 248);
  }
}
</style>
