<template>
  <Loader ref="LoaderRef" />
  
  <!-- <div class="cursor"></div> -->
  
  <component :is="layout" />
  
  <a class="scrollToTop" href="#"><i class="fa fa-angle-double-up"></i></a>

</template>
<script setup>
import AppLayoutDefault from '@/layouts/AppLayoutDefault.vue'
import { shallowRef, watch, onMounted, ref, provide } from 'vue'
import { useRoute } from 'vue-router'
import Loader from '@/components/common/service/Loader.vue'
import { useAuthStore } from '@/store/auth'
// import $ from 'jquery'
// import { TweenMax, TweenLite } from 'gsap'

const
  layout = shallowRef(null),
  route = useRoute(),
  store = useAuthStore()

watch(
  () => route.meta,
  async () => {
    try {
      const component = await import(`@/layouts/${ route.meta.layout }.vue`)
      layout.value = component?.default || AppLayoutDefault
    } catch (e) {
      layout.value = AppLayoutDefault
    }
  },
  {
    immediate: false
  }
)

const LoaderRef = ref()
provide('LoaderRef', LoaderRef)

onMounted(() => {
  const { $, WOW, Swiper } = window // TweenMax, TweenLite,
  
  if (!$) return
  // set HTML language tag
  if (store.lang) {
    const html = document.documentElement // returns the html tag
    html.setAttribute('lang', store.lang)
  }
  
  $(document).ready(function () {
    window.scrollTo(0, 0)
    
    new WOW().init()
    
    /* ---------------------------------------------
            ## Sidebar Script
        --------------------------------------------- */
    const w = $(window).width()
    const MarginTop = (w > 1199) ? 80 : 0
    if ($('.sidebar').length) {
      $('.sidebar').theiaStickySidebar({
        'containerSelector': '.blog-section',
        'additionalMarginTop': MarginTop,
        'minWidth': 992
      })
    }
    
    /* maybe improve later
    const fixed_top = $('.header-section')
    $(window).on('scroll', function () {
      if ($(window).scrollTop() > 500) {
        fixed_top.addClass('animated fadeInDown header-fixed')
      } else {
        fixed_top.removeClass('animated fadeInDown header-fixed')
      }
    })
    */
    
    // navbar-click
    $('.navbar li a').on('click', function () {
      const element = $(this).parent('li')
      if (element.hasClass('show')) {
        element.removeClass('show')
        element.find('li').removeClass('show')
      } else {
        element.addClass('show')
        element.siblings('li').removeClass('show')
        element.siblings('li').find('li').removeClass('show')
      }
    })
    
    $('.popup-youtube').magnificPopup({ type: 'video' })
    
    // scroll-to-top
    const ScrollTop = $('.scrollToTop')
    $(window).on('scroll', function () {
      if ($(this).scrollTop() < 500) {
        ScrollTop.removeClass('active')
      } else {
        ScrollTop.addClass('active')
      }
    })
    
    // Search
    /*
    $(document).on('click', '.search-bar, .skip', function () {
      $('.header-form').toggleClass('active')
    })
    */
    
    // menu bar
    $('.header-bar').on('click', function () {
      $(this).toggleClass('active')
      $('.header-btn-group').toggleClass('active wow')
    })
    
    // Overlay On Click Functions
    $(document).on('click', '.overlay', function () {
      $(this).removeClass('active')
      $('.header-bar').removeClass('active')
      $('.menu').removeClass('active')
      $('.header-top-area').removeClass('active')
    })
    
    $('.faq-wrapper .faq-title').on('click', function () {
      const element = $(this).parent('.faq-item')
      if (element.hasClass('open')) {
        element.removeClass('open')
        element.find('.faq-content').removeClass('open')
        element.find('.faq-content').slideUp(300, 'swing')
      } else {
        element.addClass('open')
        element.children('.faq-content').slideDown(300, 'swing')
        element.siblings('.faq-item').children('.faq-content').slideUp(300, 'swing')
        element.siblings('.faq-item').removeClass('open')
        element.siblings('.faq-item').find('.faq-title').removeClass('open')
        element.siblings('.taq-item').find('.faq-content').slideUp(300, 'swing')
      }
    })
    
    $('.faq-wrapper--style .faq-title').on('click', function () {
      const element = $(this).parent('.faq-item--style')
      if (element.hasClass('open')) {
        element.removeClass('open')
        element.find('.faq-content--style').removeClass('open')
        element.find('.faq-content--style').slideUp(300, 'swing')
      } else {
        element.addClass('open')
        element.children('.faq-content--style').slideDown(300, 'swing')
        element.siblings('.faq-item--style').children('.faq-content--style').slideUp(300, 'swing')
        element.siblings('.faq-item--style').removeClass('open')
        element.siblings('.faq-item--style').find('.faq-title').removeClass('open')
        element.siblings('.taq-item--style').find('.faq-content--style').slideUp(300, 'swing')
      }
    })
    
    /*
    $('.counter-number').counterUp({
      delay: 10,
      time: 5000,
      offset: 70,
      beginAt: 10,
      formatter: function (n) {
        return n.replace(/,/g, '.')
      }
    })
    */
    
    // slider
    new Swiper('.client-wrapper', {
      slidesPerView: 1,
      spaceBetween: 30,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.ruddra-next',
        prevEl: '.ruddra-prev'
      },
      autoplay: {
        speeds: 1000,
        delay: 3000
      },
      speed: 1000,
      breakpoints: {
        991: { slidesPerView: 1 },
        767: { slidesPerView: 1 },
        575: { slidesPerView: 1 }
      }
    })
    
    // slider
    new Swiper('.blog-post-wrapper', {
      slidesPerView: 1,
      spaceBetween: 30,
      loop: true,
      navigation: {
        nextEl: '.ruddra-next',
        prevEl: '.ruddra-prev'
      },
      autoplay: {
        speeds: 1000,
        delay: 3000
      },
      speed: 1500,
      breakpoints: {
        991: { slidesPerView: 1 },
        767: { slidesPerView: 1 },
        575: { slidesPerView: 1 }
      }
    })
    
    // slider
    new Swiper('.play-wrapper', {
      slidesPerView: 3,
      spaceBetween: 30,
      loop: true,
      navigation: {
        nextEl: '.ruddra-next',
        prevEl: '.ruddra-prev'
      },
      autoplay: {
        speeds: 1000,
        delay: 3000
      },
      speed: 1000,
      breakpoints: {
        991: { slidesPerView: 2 },
        767: { slidesPerView: 1 },
        575: { slidesPerView: 1 }
      }
    })
    
    $('.my-paroller').paroller()
    
    // faq Tab
    const
      tabWrapper = $('.faq-tab-wrapper'),
      tabMnu = tabWrapper.find('.tab-menu li'),
      tabContent = tabWrapper.find('.tab-cont > .tab-item')
    
    tabContent.not(':nth-child(1)').fadeOut(350)
    tabMnu.each(function (i) {
      $(this).attr('data-tab', 'tab' + i)
    })
    tabContent.each(function (i) {
      $(this).attr('data-tab', 'tab' + i)
    })
    tabMnu.on('click', function () {
      const tabData = $(this).data('tab')
      tabWrapper.find(tabContent).fadeOut(350)
      tabWrapper.find(tabContent).filter('[data-tab=' + tabData + ']').fadeIn(350)
    })
    $('.tab-menu > li').on('click', function () {
      const before = $('.tab-menu li.active')
      before.removeClass('active')
      $(this).addClass('active')
    })
    
    //Odometer
    if ($('.counter-item').length) {
      $('.counter-item').each(function () {
        $(this).isInViewport(function (status) {
          if (status === 'entered') {
            for (let i = 0; i < document.querySelectorAll(".odometer").length; i++) {
              const el = document.querySelectorAll('.odometer')[i]
              el.innerHTML = el.getAttribute('data-odometer-final')
            }
          }
        })
      })
    }
    
    $('.search-bar a').on('click', () => {
      $('.header-top-search-area').toggleClass('active')
    })
    
    // ------ cursor js ---------- //
    /*
    const $circleCursor = $('.cursor')
    
    function moveCursor(e) {
      const t = e.clientX + 'px',
        n = e.clientY + 'px'
      
      TweenMax.to($circleCursor, .2, {
        left: t,
        top: n,
        ease: 'Power1.easeOut'
      })
    }
    
    $(window).on('mousemove', moveCursor)
    
    // simple zoom
    function zoomCursor(e) {
      TweenMax.to($circleCursor, .1, {
        scale: 3,
        ease: 'Power1.easeOut'
      })
      $($circleCursor).removeClass('cursor-close')
    }
    
    $('a, .zoom-cursor').on('mouseenter', zoomCursor)
    
    // close
    function closeCursor(e) {
      TweenMax.to($circleCursor, .1, {
        scale: 3,
        ease: 'Power1.easeOut'
      })
      $($circleCursor).addClass('cursor-close')
    }
    
    $('.trigger-close').on('mouseenter', closeCursor)
    
    // default
    function defaultCursor(e) {
      TweenLite.to($circleCursor, .1, {
        scale: 1,
        ease: 'Power1.easeOut'
      })
      $($circleCursor).removeClass('cursor-close')
    }
    
    $('a, .zoom-cursor, .trigger-close, .trigger-plus').on('mouseleave', defaultCursor)
    */
    
    $('#vmap').vectorMap({
      map: 'world_en',
      color: '#2a3e72',
      backgroundColor: 'transparent',
      hoverOpacity: .8,
      selectedColor: '#ffca24',
      scaleColors: ['#f7fcff', '#f7fcff'],
      normalizeFunction: 'polynomial'
    })
  })
})
</script>
